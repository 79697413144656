import showConsentManager from './showConsentManager'

export default [
  {
    title: 'Shopping',
    links: [
      {
        title: 'Offers & Promotions',
        url: '/list/shop-all-tu-clothing-sale/?INITD=GNav-Sale-Footer&clickOrigin=footer:click:menu:offersPromotions',
      },
      {
        title: 'Nectar at Tu',
        url: '/help/nectar',
      },
      {
        title: 'Keyworker Discount',
        url: 'https://secure.gocertify.me/at/tu-clothing/key-worker',
      },
    ],
  },
  {
    title: 'Need Help?',
    links: [
      {
        title: 'Help Centre',
        url: '/help?clickOrigin=footer:click:menu:help',
      },
      {
        title: 'Contact Us',
        url: '/help/contact-us?INITD=GNav-Footer-ContactUs&clickOrigin=footer:click:menu:contactUs',
      },
      {
        title: 'Store Finder',
        url: '/store-finder?clickOrigin=footer:click:menu:storeFinder',
      },
    ],
  },
  {
    title: 'Delivery & Returns',
    links: [
      {
        title: 'Delivery Information',
        url: '/help/delivery-information?clickOrigin=footer:click:menu:deliveryInformation',
      },
      {
        title: 'Returns & Refunds',
        url: '/help/returns-and-refunds?INITD=GNav-Footer-ReturnsRefunds&clickOrigin=footer:click:menu:returnsRefunds',
      },
      {
        title: 'Track your order',
        url: 'https://tuclothing.sainsburys.co.uk/order/tracking',
      },
    ],
  },
  {
    title: 'Corporate Responsibility',
    links: [
      {
        title: 'Sustainability',
        url: '/features/sustainability?clickOrigin=footer:click:menu:sustainability',
      },
      {
        title: 'Product Recall',
        url: '/help/product-recall?clickOrigin=footer:click:menu:productRecall',
      },
    ],
  },
  {
    title: 'Policies',
    links: [
      {
        title: 'Cookie Policy',
        url: 'https://privacy-hub.sainsburys.co.uk/cookie-policy/',
      },
      {
        title: 'List of Cookies',
        url: '/help/list-of-cookies?clickOrigin=footer:click:menu:listOfCookies',
      },
      {
        title: 'Cookie Preferences',
        attributes: {
          // This onClick handlers will get spread onto the anchor tag, and override it's default behaviour.
          // In environments rendered by Hybris, this click handler will be overwritten by Hybris' consent manager hook.
          // https://github.com/JSainsburyPLC/tu-app/blob/cd747201fe7b375071e75e5ff353857e5da32d49/ext-tu/tustorefront/web/webroot/WEB-INF/_ui-src/2017/scripts/scriptlets/resetTealiumCookiePreferences.js#L4-L9
          onClick: showConsentManager,
          'data-js': 'cookie-preferences',
        },
        url: 'https://privacy-hub.sainsburys.co.uk/cookie-policy/',
      },
      {
        title: 'Modern Slavery Statement',
        url:
          'https://www.about.sainsburys.co.uk/sustainability/plan-for-better/our-stories/2017/standing-up-to-modern-slavery',
      },
    ],
  },
]
