import React from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalHeading } from '@jsluna/modal'
import PartOfTheFamilyLogo from '../PartOfTheFamilyLogo/PartOfTheFamilyLogo'
import { SIGN_IN_URL, REGISTER_URL, ACCOUNT_HELP_URL, TRACK_ORDER_URL } from '../Header/analytics'

import styles from './AccountModal.module.scss'

export default function AccountModal({ isOpen, onClose }) {
  return (
    <div className={styles.modalWrapper}>
      <Modal small fullScreen handleClose={() => onClose()} open={isOpen} headingId="header-account-modal">
        <ModalHeading className={styles.modalHeading} element="h3">
          <PartOfTheFamilyLogo />
        </ModalHeading>
        <div className={styles.wrapper} data-testid="Header/AccountModal">
          <h4 className={`${styles.bold} ${styles.heading}`}>Your combined Argos & Tu account</h4>
          <p>Use your Argos account to shop with Tu and manage your details in one place.</p>
          <p>Sign in to access:</p>
          <ul>
            <li>All your Tu and Argos orders</li>
            <li>Your saved payment and address details for Tu, Argos and Habitat</li>
          </ul>
          <div className={styles.buttonWrapper}>
            <a className={styles.button} href={SIGN_IN_URL} data-testid="Header/AccountModalSignIn">
              Sign in on Argos.co.uk
            </a>
            <a className={styles.button} href={TRACK_ORDER_URL} data-testid="Header/TrackOrder">
              Track my order without signing in
            </a>
          </div>
          <p>
            You can still add new payment and address details when you&apos;re at checkout. If you don&apos;t have an
            Argos account, you can check out as a guest or{' '}
            <a href={REGISTER_URL} className={`${styles.bold} ${styles.textLink}`}>
              create an Argos account
            </a>{' '}
            to shop with Tu.
          </p>
          <h5 className={`${styles.bold} ${styles.heading}`}>Got questions?</h5>
          <a href={ACCOUNT_HELP_URL} className={`${styles.bold} ${styles.textLink}`}>
            Find out more about your account
          </a>
        </div>
      </Modal>
    </div>
  )
}

AccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
